import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location) {

    return routerPush.call(this, location).catch(err => {})

};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name:'home'
    },
    component: ()=> import('../components/monitoring/navigation.vue'),
    children:[
      {
        path: '/home',
        name: 'home',
        
        component: () => import(/* webpackChunkName: "about" */ '../components/home/home.vue')
      },
      {
        path:'/about',
        redirect: {
          name:'introduction'
        },
        component:()=>import('../components/about/about'),
        children:[
          {
            path:'/culture',
            name:'culture',
            component:()=>import('../components/about/culture'),
          },
          {
            path:'/introduction',
            name:'introduction',
            component:()=>import('../components/about/introduction'),
          },
          {
            path:'/mechanism',
            name:'mechanism',
            component:()=>import('../components/about/mechanism'),
          },
          {
            path:'/cooperation',
            name:'cooperation',
            component:()=>import('../components/about/cooperation'),
          },
          {
            path:'/history',
            name:'history',
            component:()=>import('../components/about/history')
          }
        ]
      },
      {
        path:'/news',
        redirect: {
          name:'company'
        },
        component:()=>import('../components/news/news'),
        children:[
          {
            path:'/company',
            name:'company',
            component:()=>import('../components/news/company')
          },
          {
            path:'/industry',
            name:'industry',
            component:()=>import('../components/news/industry')
          },
          {
            path:'/newIndex',
            name:'newIndex',
            component:()=>import('../components/news/newIndex')
          }
        ]
      },
      {
        path:'/search',
        name:'search',
        component:() =>import('../components/search/searchIndex')
      },
      {
        path:'/service',
        redirect: {
          name:'kit'
        },
        component:()=>import('../components/service/service'),
        children:[
          {
            path:'/kit',
            name:'kit',
            component:()=>import('../components/service/kit'),
          },
          {
            path:'/value-added',
            name:'value-added',
            component:()=>import('../components/service/value-added'),
          },
          {
            path:'/Specialtopic',
            name:'Specialtopic',
            component:()=>import('../components/service/Specialtopic'),
          },
          {
            path:'/download',
            name:'download',
            component:()=>import('../components/service/download'),
          },
          {
            path:'/LeaveMessage',
            name:'LeaveMessage',
            component:()=>import('../components/service/LeaveMessage'),
          },
          {
            path:'/serviceIndex',
            name:'serviceIndex',
            component:()=>import('../components/service/serviceIndex'),
          },
        ]
      },
      {
        path:'/contact',
        redirect: {
          name:'mode'
        },
        component:()=>import('../components/contact/contact'),
        children:[
          {
            path:'/mode',
            name:'mode',
            component:()=>import('../components/contact/mode')
          },
          {
            path:'/recruitment',
            name:'recruitment',
            component:()=>import('../components/contact/recruitment')
          }
        ]
      },
      {
        path:'/solve',
        redirect:{
          name:'motor'
        },
        component:()=>import('../components/solve/solve'),
        children:[
          {
            name:'motor',
            path:'/motor',
            component:()=>import('../components/solve/motor')
          },
          {
            name:'intelligence',
            path:'/intelligence',
            component:()=>import('../components/solve/intelligence')
          },
          {
            name:'tool',
            path:'/tool',
            component:()=>import('../components/solve/tool.vue')
          },
          {
            name:'other',
            path:'/other',
            component:()=>import('../components/solve/other.vue')
          },
          {
            name:'solveIndex',
            path:'/solveIndex',
            component:()=>import('../components/solve/solveIndex.vue')
          },
        ]
      },
      {
        path:'/product',
        redirect:{
          name:'controller'
        },
        component:()=>import('../components/product/product.vue'),
        children:[
          {
            path:'/controller',
            name:'controller',
            component:()=>import('../components/product/controller.vue'),
          },
          {
            path:'/controllerIndex',
            name:'controllerIndex',
            component:()=>import('../components/product/controllerIndex.vue'),
          },
          {
            path:'/peripheral',
            name:'peripheral',
            component:()=>import('../components/product/peripheral.vue')
          },
          {
            path:'/peripheralIndex',
            name:'peripheralIndex',
            component:()=>import('../components/product/peripheralIndex.vue')
          }
        ]
      }
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
