import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
import 'lib-flexible/flexible.js'
import "@/assets/css/commot.css"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import  BaiduMap  from 'vue-baidu-map';
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'AqW7Fd715B9scS4o9fhxHCgs7nrI73xX' // 百度地图秘钥
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
